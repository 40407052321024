import './App.css';
import React, { useEffect, useState } from 'react';

import Alert from './component/Alert';

import CoreVenture_Before_Login from './component/CoreVenture_Before_Login';
import CoreVenture_After_Login from './component/CoreVenture_After_Login';

import { useWeb3Modal } from '@web3modal/wagmi/react';

import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import loading_gif from './component/images/loading_gif.gif';

import { useAccount } from 'wagmi';

//---------

import { Refferal_Detail, Refferal_Free_SignUp, Login_Detail } from './component/Data_Structure';

//---------------------

let url = "https://coreventure.space/";
//let url = "http://localhost:3000/";

let direct_login = false;
// let direct_login = true;

//---------------------------Modal_Area---------------------------------------------------

function LoadingModal(props) {

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    }
  }, []);
  return (
    <>     
      <div className='modal-wrapper' style={{zIndex: 100}}></div>
        {/*<div className='fixed-top'>
            <div style={{ZIndex: 9, height: '56px', backgroundColor: 'rgba(189, 189, 189, 0.9)'}}></div>
        </div>*/}
      {
        props.isLoading === true ? (
          <>
            <div className='modal-container' style={{zIndex: 101}}>
              <table style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td style={{width: '100%'}}>
                      
                      <img style={{width: '80px', height: '80px'}} src={loading_gif} alt="loading" />
                          
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <></>
        )
      }
    </>
  );
}

//----------------

function Main_Component(props) {

  const {address} = useAccount();

  const { open } = useWeb3Modal();

  const [Login_Try_Count, SetLogin_Try_Count] = useState(Number(0));
  const [IsLogin, SetIsLogin] = useState(false);
  
  const [LoadingModal_Show, SetLoadingModal_Show] = useState(false);
  const [LoadingModal_isLoading, SetLoadingModal_IsLoading] = useState(true);

  const SetLoadingModal = async(istrue, isloading) => {
    SetLoadingModal_Show(istrue);
    SetLoadingModal_IsLoading(isloading);
  }

  const SignIn_Click = async() => {
    if (direct_login === false) {
      open();
    }
    else if (direct_login === true) {
      SetIsLogin(true);
    }
  }
  const SignUp_Click = async() => {
    let id_no = Number(0);
    do {
      let rndInt = Math.floor(Math.random() * 7) + 1;
      if (Number(rndInt) > Number(1)) {
        //console.log(rndInt);
        id_no = rndInt;
        break;
      }
    }
    while (true);
    
    if (Number(id_no) > Number(1)) {
      let ref;
      if (Number(id_no) === Number(2)) {
        ref = "51846295";
      }
      else if (Number(id_no) === Number(3)) {
        ref = "68464684";
      }
      else if (Number(id_no) === Number(4)) {
        ref = "51684684";
      }
      else if (Number(id_no) === Number(5)) {
        ref = "68488497";
      }
      else if (Number(id_no) === Number(6)) {
        ref = "84468748";
      }
      else if (Number(id_no) === Number(7)) {
        ref = "58468468";
      }
      window.location.replace(url + "r/" + ref);
    }
  }

  useEffect(() => {
    const Check_Address = async() => {
      if (address === undefined || address === "" || address === " ") {
        SetIsLogin(false);
      }
      else {
        Login_Detail(address, SetIsLogin, props.showAlert);
      }
      
      if (Number(Login_Try_Count) === Number(0)) {
        SetLogin_Try_Count(Number(1));
      }
      else if (Number(Login_Try_Count) === Number(1)) {
        SetLogin_Try_Count(Number(2));
      }
      else if (Number(Login_Try_Count) === Number(2)) {
        SetLogin_Try_Count(Number(2));
      }
    }
    Check_Address();
  }, [address, Login_Try_Count]);

  //--#6610f2
  //--#6710f2aa dark versino

  return (
    <>
      {
        IsLogin === false ? (
          <>
            <CoreVenture_Before_Login SignIn_Click={SignIn_Click} SignUp_Click={SignUp_Click} showAlert={props.showAlert}/>
          </>
        ) : IsLogin === true ? (
          <>
            <CoreVenture_After_Login SetIsLogin={SetIsLogin} SetLoadingModal={SetLoadingModal} showAlert={props.showAlert}/>
          </>
        ) : (
          <></>
        )
      }

      {
        LoadingModal_Show === true ? (
          <>
            <LoadingModal isLoading={LoadingModal_isLoading} SetLoadingModal={SetLoadingModal}/>
          </>
        ) : (
          <></>
        )
      }
    </>
  );
}
function Refferal_Component(props) {

  const {address} = useAccount();

  const param = useParams();

  const [LoadingModal_Show, SetLoadingModal_Show] = useState(false);
  const [LoadingModal_isLoading, SetLoadingModal_IsLoading] = useState(true);
  
  const [Upline_Address, SetUpline_Address] = useState('');
  
  const { open } = useWeb3Modal();

  const SetLoadingModal = async(istrue, isloading) => {
    SetLoadingModal_Show(istrue);
    SetLoadingModal_IsLoading(isloading);
  }
  
  
  useEffect(() => {
    const Check_Refferal = async() => {
      if (param.id !== '') {
        //console.log(param.id);
        SetLoadingModal(true, true);
        //props.showAlert("Param Id is " + param.id, "success", true);
        Refferal_Detail(param.id, SetUpline_Address, open, props.showAlert, SetLoadingModal);
      }
      else {
      }      
    }
    Check_Refferal();
  }, [document.URL]);

  useEffect(() => {
    const Check_Address = async() => {
      if (address === undefined || address === "" || address === " ") {
        //console.log("Address : " + address);
      }
      else {
        //console.log("Add: " + address);
        Refferal_Free_SignUp(address, Upline_Address, props.showAlert, SetLoadingModal);
      }
    }
    Check_Address();
  }, [address]);

  return (
    <>
      { 
        LoadingModal_Show === true ? (
          <>
            <LoadingModal isLoading={LoadingModal_isLoading} SetLoadingModal={SetLoadingModal}/>
          </>
        ) : (
          <></>
        )
      }
    </>
  );
}

function App() {

    
  const [alert, setAlert] = useState(null);

  //-------------Alert-Area--------------------
  const showAlert = (message, type, timeout_true) => {
    setAlert({
      message: message,
      type: type,
    });

    if (timeout_true === true) {
      setTimeout(() => {
        setAlert(null);
      }, 6000);
    }
  }

  useEffect(() => {
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <div className="App" style={{overflowX: "hidden"}}>
        <Alert alert={alert}/>

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main_Component showAlert={showAlert}/>}></Route>
            <Route path="/r/:id" element={<Refferal_Component showAlert={showAlert}/>}></Route>
            {/*<Route path="/p/:id" element={<Check_Package/>}></Route>*/}
          </Routes>
        
        </BrowserRouter>

    </div>
  );
}

export default App;
